import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

/*
  Generated class for the SponsorsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

export interface Sponsor {
  name: string;
  status: string;
  updated_at: Date;
  image: string;
  description: string;
  url: string;
  [key: string]: any;
}
@Injectable()
export class SponsorsProvider {
  private itemDoc: AngularFirestoreDocument<Sponsor>;
  constructor(private afs: AngularFirestore) {
    /// this.itemDoc = this.afs.doc<Sponsor>('sponsors/' + sponsorId);
    console.log('Hello SponsorsProvider Provider');
  }

  getSponsor(sponsorId: string) {
    this.itemDoc = this.afs.doc<Sponsor>('sponsors/' + sponsorId);
    const item: Observable<Sponsor> = this.itemDoc.valueChanges();
    return item;
  }

  // getSponsor(spon: string): Observable<Sponsor[]> {
  //   let courseCollection: AngularFirestoreCollection<Course> = this.afs.collection('courses', (ref) => ref
  //     .orderBy('name', 'asc')
  //     .where('status', '==', 'published')
  //     .where('shoots', '==', shootId));

  //   return courseCollection.snapshotChanges().map((actions) => {
  //     return actions.map((a) => {
  //       const data = a.payload.doc.data() as Course;
  //       return { id: a.payload.doc.id, name: data.name, status: data.status,
  //         description: data.description, updated_at: data.updated_at, sponsor: data.sponsor,
  //         image: data.image };
  //     });
  //   });

}
