import { Component, Input, OnInit } from '@angular/core';
import { Course } from '../../providers/course/course';
import { ScoreProvider, Metrics } from '../../providers/score/score';
/**
 * Generated class for the ScoreComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'score',
  templateUrl: './score.html',
  styleUrls: ['./score.scss']
})
export class ScoreComponent implements OnInit{

  @Input() course: Course;
  metrics: Array<Metrics> = [] as Metrics[];
  loaded: boolean = true;

  constructor(private score: ScoreProvider) {
  }

  ngOnInit() {
    console.log('score.ngOnInit().course', this.course);
    this.score.getMetricsByCourse(this.course).subscribe((metrics: Array<Metrics>) => {
      console.log('Get Metrics Dashboard => ', metrics);
      this.metrics = metrics;
      if (metrics.length) {
        this.loaded = true;
      }
    });
  }

}
