import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Sponsor } from '../sponsors/sponsors';
// import { Sponsor } from '../sponsors/sponsors';

/*
  Generated class for the CourseProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export interface Course {
  name: string;
  status: string;
  updated_at: Date;
  image: string;
  description: string;
  sponsor: Sponsor;
  stations: number;
  scoring: string;
  dashboard: boolean;
  [key: string]: any;
}

@Injectable()
export class CourseProvider {

  courseCollection: AngularFirestoreCollection<Course>;
  selectedCourse: AngularFirestoreDocument<Course>;
  course: Observable<Course>;
  constructor(private afs: AngularFirestore) {
    this.courseCollection = this.afs.collection('courses', (ref) => ref
      .orderBy('name', 'asc')
      .where('status', '==', 'published')
    );
  }

  getData(): Observable<Course[]> {
    return this.courseCollection.valueChanges();
  }

  getSnapshot(): Observable<Course[]> {
    // ['added', 'modified', 'removed']
    return this.courseCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Course;
        return { id: a.payload.doc.id, name: data.name, status: data.status,
          description: data.description, updated_at: data.updated_at, sponsor: data.sponsor, stations: data.stations,
          image: data.image, scoring: data.scoring, dashboard: data.dashboard };
      });
    }));
  }

  getCoursesByShoot(shootId: string): Observable<Course[]> {
    let courseCollection: AngularFirestoreCollection<Course> = this.afs.collection('courses', (ref) => ref
      .orderBy('name', 'asc')
      .where('status', '==', 'published')
      .where('shoots', '==', shootId));

    return courseCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Course;
        return { id: a.payload.doc.id, name: data.name, status: data.status,
          description: data.description, updated_at: data.updated_at, sponsor: data.sponsor, stations: data.stations,
          image: data.image, scoring: data.scoring, dashboard: data.dashboard };
      });
    }));
  }

  getCoursesWithDashboardByShoot(shootId: string): Observable<Course[]> {
    console.log('getCoursesWithDashboardByShoot->', shootId);
    const courseCollection: AngularFirestoreCollection<Course> = this.afs.collection('courses', (ref) => ref
      .orderBy('name', 'asc')
      .where('status', '==', 'published')
      .where('shoots', '==', shootId)
      .where('dashboard', '==', true)
      .limit(20)
    );

    return courseCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Course;
        return { id: a.payload.doc.id, name: data.name, status: data.status,
          description: data.description, updated_at: data.updated_at, sponsor: data.sponsor, stations: data.stations,
          image: data.image, scoring: data.scoring, dashboard: data.dashboard };
      });
    }));
  }

}
