import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserGuard } from './providers/user.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'course',
    loadChildren: () => import('./pages/course/course.module').then( m => m.CoursePageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'course-run',
    loadChildren: () => import('./pages/course-run/course-run.module').then( m => m.CourseRunPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'course-score',
    loadChildren: () => import('./pages/course-score/course-score.module').then( m => m.CourseScorePageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'shootschedule',
    loadChildren: () => import('./pages/shootschedule/shootschedule.module').then( m => m.ShootschedulePageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'shoot',
    loadChildren: () => import('./pages/shoot/shoot.module').then( m => m.ShootPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'shoot-group-add-user',
    loadChildren: () => import('./pages/shoot-group-add-user/shoot-group-add-user.module').then( m => m.ShootGroupAddUserPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'target-score',
    loadChildren: () => import('./pages/target-score/target-score.module').then( m => m.TargetScorePageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
