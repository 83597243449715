import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router, NavigationStart } from '@angular/router';
import { ShootsProvider, Shoot } from '../../providers/shoots/shoots';
import { CoursePage } from '../course/course.page';
import { Observable } from 'rxjs';
// import { DashboardPage } from '../dashboard/dashboard.page';
import { ShootschedulePage } from '../shootschedule/shootschedule.page';

@Component({
  selector: 'app-shoot',
  templateUrl: './shoot.page.html',
  styleUrls: ['./shoot.page.scss'],
})
export class ShootPage implements OnInit {
  shoots: Observable<Shoot[]>;
  constructor(public navCtrl: NavController, private shootsProvider: ShootsProvider, private router: Router) { }

  ngOnInit() {
    console.log('ionViewDidLoad ShootPage');
    this.shoots = this.shootsProvider.getSnapshot();
  }

  ionViewDidLoad() {
    // console.log('ionViewDidLoad ShootPage');
    // this.shoots = this.shootsProvider.getSnapshot();
  }

  goToCourse(shoot: Shoot) {
    this.router.navigate(['/course'], {state: {data: shoot}});
    // this.navCtrl.push(CoursePage, {
    //   shoot: shoot
    // });
  }

  goToDashboard(shoot: Shoot) {
    this.router.navigate(['/dashboard'], {state: {data: shoot}});
    // this.navCtrl.push(DashboardPage, {
    //   shoot: shoot
    // });
  }
  goToSchedule(shoot: Shoot) {
    this.router.navigate(['/shootschedule'], {state: {data: shoot}});
    // this.navCtrl.push(ShootschedulePage, {
    //   shoot: shoot
    // });
  }

}
