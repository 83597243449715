import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastController } from '@ionic/angular';

/// Notify users about errors and other helpful stuff
export interface Msg {
  content: string;
  style: string;
}

@Injectable()
export class NotifyProvider {

  private msgSource = new Subject<Msg | null>();
  msg = this.msgSource.asObservable();

  constructor(private toastCtrl: ToastController) {
    console.log('Hello NotifyProvider Provider');
  }

  update(content: string, style: 'error' | 'info' | 'success') {
    const msg: Msg = { content, style };
    this.msgSource.next(msg);
    this.presentToast(content);
    // const toast = this.toastCtrl.create({
    //   message: content,
    //   duration: 3000,
    //   position: 'top',
    //   cssClass: 'dark-trans',
    // });

    // toast.onDidDismiss(() => {
    //   console.log('Dismissed');
    // });

    // toast.present();
  }

  clear() {
    this.msgSource.next(null);
  }

  async presentToast(content: string) {
    const toast = await this.toastCtrl.create({
      message: content,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

}
