import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { switchMap, first, map } from 'rxjs/operators';
import { NotifyProvider } from '../notify/notify';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';

/*
  Generated class for the AuthorizeProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export interface FireUser {
  displayName?: string;
  email: string;
  emailVerified?: boolean;
  isAnonymous: boolean;
  metadata?: string;
  phoneNumber?: string;
  photoURL: string;
  providerData?: Array<any>;
  providerId?: string;
  refreshToken?: string;
  uid: string;
}
interface User {
  uid: string;
  email: string;
  photoURL: string;
  displayName?: string;
  catchPhrase?: string;
}

@Injectable()
export class AuthorizeProvider {
  // public user: Observable<firebase.User>;
  public user: Observable<FireUser>;
  constructor(
    private firebaseAuthentication: FirebaseAuthentication,
    private afAuth: AngularFireAuth,
    private gplus: GooglePlus,
    private platform: Platform,
    private afs: AngularFirestore,
    private router: Router,
    // public loadingCtrl: LoadingController,
    private notify: NotifyProvider) {
      this.user = this.afAuth.authState
      .pipe(
      switchMap(user => {
        if (user) {
          // logged in, get custom user from Firestore
          return this.afs.doc<FireUser>(`users/${user.uid}`).valueChanges();
        } else {
          // logged out, null
          // return Observable.of(null)
          return Observable;
        }
      }));
  }

  googleLogin() {
    if (this.platform.is('cordova')) {
      console.log('Running Cordova');
      // this.nativeGoogleLogin();
      this.doGoogleLogin().then(() => {
        this.router.navigate(['/shoot']);
      });
    } else {
      console.log('Running Web');
      this.webGoogleLogin().then(() => {
        this.router.navigate(['/shoot']);
      });
    }
  }

  signOut() {
    this.afAuth.signOut().then(() => {
      this.notify.update('Success Logout', 'success');
      this.router.navigate(['/login']);
    }).catch(error => {
      this.notify.update(error, 'error');
    });
  }

  // doGoogleLogin2(){
  //   let nav = this.navCtrl;
  //   let env = this;
  //   let loading = this.loadingCtrl.create({
  //     content: 'Please wait...'
  //   });
  //   loading.present();
  //   this.googlePlus.login({
  //     'scopes': '', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
  //     'webClientId': '676081794606-vc7mii89hsabfkl48i4gn5rl0g5d2eko.apps.googleusercontent.com', // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
  //     'offline': true
  //   })
  //   .then(function (user) {
  //     loading.dismiss();

  //     env.nativeStorage.setItem('user', {
  //       name: user.displayName,
  //       email: user.email,
  //       picture: user.imageUrl
  //     })
  //     .then(function(){
  //       nav.push(UserPage);
  //     }, function (error) {
  //       console.log(error);
  //     })
  //   }, function (error) {
  //     loading.dismiss();
  //   });
  // }

  async nativeGoogleLogin(): Promise<void> {
    // console.log('fingerprint:', await this.gplus.getSigningCertificateFingerprint());
    try {
      // this.gplus.login({
      //   'webClientId': '676081794606-foksf2jp9pblalbdsjs89ntc3ktfd690.apps.googleusercontent.com',
      //   'offline': true,
      //   'scopes': 'profile email'
      // }).then((res) => {
      //     console.log(res);
      // }, (err) => {
      //     console.log(err);
      // });

      const gplusUser = await this.gplus.login({
        'webClientId': '676081794606-vc7mii89hsabfkl48i4gn5rl0g5d2eko.apps.googleusercontent.com',
        'offline': true,
        'scopes': '' // profile email
      }).catch(err => {
        // this.gplus.getSigningCertificateFingerprint().then((key) => {
        //   this.notify.update(key , 'error');
        // });
        console.log('There is an error ' + JSON.stringify(err));
      });

      const credentials = firebase.auth.GoogleAuthProvider.credential(gplusUser.idToken);

      const credential = await firebase.auth().signInWithCredential(credentials);
      const user = this.buildCredentialUser(credential);
      if (credential.user.displayName) {
        user.displayName = credential.user.displayName;
      }
      return this.updateUserData(credential.user, user);

    } catch (err) {
      // this.notify.update(await this.gplus.getSigningCertificateFingerprint(), 'error');
      this.notify.update('error 1:' + err, 'error');
      console.log('native google login error =>', err);
    }
  }

  // doFirebaseAuth() {
  //   let provider = new auth.GoogleAuthProvider();
  //   this.firebaseAuthentication.signInWithGoogle()
  //   // return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  //Authentication for Login
  // AuthLogin(provider) {
  //   return this.afAuth.auth.signInWithPopup(provider)
  //     .then((result) => {
  //       this.ngZone.run(() => {
  //         this.router.navigate(['/dashboard/default']);
  //       });
  //       this.SetUserData(result.user);
  //     }).catch((error) => {
  //       window.alert(error);
  //     });
  // }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      if (this.platform.is('cordova')){
        this.gplus.getSigningCertificateFingerprint().then((key) => {
          console.log('key =>', key);
          this.notify.update(key , 'error');
        });
        this.gplus.login({
          'scopes': '', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
          'webClientId': '676081794606-vc7mii89hsabfkl48i4gn5rl0g5d2eko.apps.googleusercontent.com', // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
          'offline': true
        }).then((response) => {
          const googleCredential = firebase.auth.GoogleAuthProvider.credential(response.idToken);
          firebase.auth().signInWithCredential(googleCredential)
          .then((cred) => {
            resolve({
              userId: cred.user.uid,
              name: cred.user.displayName,
              image: cred.user.photoURL
            });
          });
        },
        (err) => {
         this.notify.update('error 2:' + err , 'error');
         reject(err);
        });
      }
      else{
        this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then((user) => {
          resolve({
            userId: user.user.uid,
            name: user.user.displayName,
            image: user.user.photoURL
          });
        });
      }
    });
  }

  async webGoogleLogin(): Promise<void> {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credential = await this.afAuth.signInWithPopup(provider);
      const user = this.buildCredentialUser(credential.user);
      console.log('webGoogleLogin => user', credential, user);
      if (credential.user.displayName) {
        user.displayName = credential.user.displayName;
      }
      return this.updateUserData(credential.user, user);

    } catch (err) {
      console.log('web google login error =>', err);
    }

  }

  async emailLogin2(email: string, password: string): Promise<void>  {
    try {
      // const provider = new firebase.auth.EmailAuthProvider();
      const credential: any = await this.afAuth.signInWithEmailAndPassword(email, password);
      console.log('credential', credential);
      const user = this.buildCredentialUser(credential);
      if (credential.displayName) {
        user.displayName = credential.displayName;
        return this.updateUserData(credential, user);
      }
    } catch (error) {
    }
  }

  buildCredentialUser(credential): FireUser{
    const user: FireUser = {
      // displayName: credential.displayName,
      email: credential.user.email,
      emailVerified: credential.user.emailVerified || false,
      isAnonymous: credential.user.isAnonymous || false,
      metadata: null,
      phoneNumber: credential.user.phoneNumber || null,
      photoURL: credential.user.photoURL || 'https://app.shoothero.us/assets/icon/auth-logo.png',
      providerData: null,
      providerId: credential.user.providerId || null,
      refreshToken: credential.user.refreshToken || null,
      uid: credential.user.uid
    };
    return user;
  }

  //// Email/Password Auth ////
  emailSignUp(email: string, password: string, displayName: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(credential => {
        console.log('create Credential =>', credential);
        this.notify.update('Created Account', 'success');
        return this.setUserData(credential.user, displayName); // if using firestore
      })
      .catch(error => this.handleError(error));
  }

  emailLogin(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(credential => {
        // this.notify.update('Welcome to Firestarter!!!', 'success');
        const user = this.buildCredentialUser(credential);
        if (credential.user.displayName) {
          user.displayName = credential.user.displayName;
        }
        return this.updateUserData(credential.user, user);
      })
      .catch(error => {
        console.log('emailLogin =>', error);
        this.handleError(error);
      });
  }

  async emailLogin3(email: string, password: string) {
    console.log('emailLogin3.email.password->', email, password);
    try {
      const cred = await this.afAuth.signInWithEmailAndPassword(email, password);
      console.log('emailLogin3.cred->', cred);
      let user = this.buildCredentialUser(cred);
      console.log('emailLogin3.user->', user);
      if (cred.user.displayName) {
        user.displayName = cred.user.displayName;
      }
      console.log('emailLogin3.user.update->', user);
      await this.updateUserData(cred.user, user);
      return cred;
    } catch (error) {
      console.log('emailLogin.error =>', error);
      this.handleError(error);
    }
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const fbAuth = firebase.auth();

    return fbAuth
      .sendPasswordResetEmail(email)
      .then(() => this.notify.update('Password update email sent', 'info'))
      .catch(error => this.handleError(error));
  }

  isLoggedIn(): Promise<any> {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.notify.update(error.message , 'error');
  }

    // Sets user data to firestore after succesful login
  private setUserData(user: User, displayName?: string) {
    console.log('setUserData =>', user);
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `users/${user.uid}`
    );

    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || displayName,
      photoURL: user.photoURL || 'https://app.shoothero.us/assets/icon/auth-logo.png'
    };
    console.log('making setUserData =>', data);
    return userRef.set(data);
  }

  public updateUserData(user: User, data: FireUser) {
    console.log('updateUserData =>', user, data);
    return this.afs.doc(`users/${user.uid}`).set(data, { merge: true });
  }

}
