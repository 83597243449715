import { Component, OnInit } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthorizeProvider, FireUser } from './providers/authorize/authorize';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// import { TabsPage } from './pages/tabs/tabs';
// import { AboutPage } from './pages/about/about';
// import { ContactPage } from './pages/contact/contact';
import { HomePage } from './pages/home/home.page';
import { ShootPage } from './pages/shoot/shoot.page';
import { ProfilePage } from './pages/profile/profile.page';

export interface MenuItem {
  title: string;
  component: any;
  icon: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  user: Observable<firebase.User>;
  // rootPage: any = LoginPage;
  appMenuItems: Array<MenuItem>;
  userb: FireUser;
  loggedIn: boolean = false;

  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'mail'
    },
    {
      title: 'Shoots',
      url: '/shoot',
      icon: 'paper-plane'
    },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public afAuth: AngularFireAuth,
    private auth: AuthorizeProvider,
    private afs: AngularFirestore,
    private menu: MenuController,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();

      // this.afs.firestore.settings({timestampsInSnapshots: true});
      this.afs.firestore.enablePersistence();
      const user = this.afAuth.authState
      .pipe(switchMap(u => {
        if (u) {
          // logged in, get custom user from Firestore
          return this.afs.doc<FireUser>(`users/${u.uid}`).valueChanges();
        } else {
          // logged out, null
          return EMPTY;
        }
      }));
      user.subscribe(userb => {
        this.userb = userb as FireUser;
      });
      this.appMenuItems = [
        {title: 'Home', component: HomePage, icon: 'home'},
        // {title: 'Contact', component: ContactPage, icon: 'partly-sunny'},
        // {title: 'AboutPage', component: AboutPage, icon: 'information-circle'},
        {title: 'Shoots', component: ShootPage, icon: 'star'}
      ];
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      //  this.keyboard.disableScroll(true);
      this.user = this.afAuth.authState;
      this.checkIsLoggedIn();
      this.splashScreen.hide();
    });
  }

  async checkIsLoggedIn() {
    this.loggedIn = await this.auth.isLoggedIn();
    if (this.loggedIn) {
      // this.menu.open();
    } else {
      this.menu.close();
    }
  }

  ngOnInit() {
    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }

  }

  authState(auth) {
    console.log('auth:', auth);
    // if(auth) {
    //   this.rootPage = ShootPage;
    // } else {
    //   this.rootPage = LoginPage;
    // }
  }

  openPage(page) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    // this.nav.setRoot(page.component);
  }

  async logout() {
    this.menu.close();
    this.auth.signOut();
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/login']);
    }
    );
    this.checkIsLoggedIn();
  }

  editProfile() {
    this.router.navigate(['/profile']);
    this.menu.close();
    // this.nav.push(ProfilePage);
  }
}
