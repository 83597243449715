import { Injectable } from '@angular/core';
import { Time } from '@angular/common';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/*
  Generated class for the ShootsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export interface Shoot {
  name: string;
  status: string;
  updated_at: Date;
  startDate: Date;
  endDate: Date;
  startTime: Time;
  image: string;
  description: string;
  city: string;
  state: string;
  schedule: Array<Schedule>;
  [key: string]: any;
}

export interface Schedule {
  day: string;
  data: Array<ScheduleInfo>;
}

export interface ScheduleInfo {
  desc: string;
  time: string;
}

@Injectable()
export class ShootsProvider {
  shootCollection: AngularFirestoreCollection<Shoot>;
  constructor(private afs: AngularFirestore) {
    this.shootCollection = this.afs.collection('shoots', (ref) => ref.orderBy('startDate', 'asc').where('status', '==', 'published'));
  }

  getData(): Observable<Shoot[]> {
    return this.shootCollection.valueChanges();
  }

  getSnapshot(): Observable<Shoot[]> {
    // ['added', 'modified', 'removed']
    return this.shootCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Shoot;
        return { id: a.payload.doc.id, name: data.name, status: data.status,
          description: data.description, updated_at: data.updated_at,
          startDate: data.startDate, endDate: data.endDate, city: data.city, state: data.state,
          startTime: data.startTime, image: data.image, schedule: data.schedule };
      });
    }));
  }

  // getUpcomingShoots(){
  //   return this.afs.collection('shoots', ref =>
  //     ref.orderBy('startDate', 'asc').limit(10))
  // }

  getShoot(id: string) {
    return this.afs.doc<Shoot>(`shoots/${id}`);
  }

  // create(content: string, description: string) {
  //   const shoot = {
  //     content,
  //     hearts: 0,
  //     time: new Date().getTime(),
  //     description,
  //   };
  //   return this.notesCollection.add(shoot);
  // }

  // updateNote(id: string, data: Partial<Shoot>) {
  //   return this.getNote(id).update(data);
  // }

  // deleteNote(id: string) {
  //   return this.getNote(id).delete();
  // }

}
