import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { AppRoutingModule } from './app-routing.module';

import { AuthorizeProvider, CourseProvider, FirestoreProvider, NotifyProvider, ScoreProvider,
         ShootGroupsProvider, ShootsProvider, SponsorsProvider, StationsProvider } from './providers';

// ******** Angularfire ******** /
import { AngularFireModule } from '@angular/fire';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';

// ******* firebase api key ******** //
import { environment } from '../environments/environment';

// ******* firebase api key ******** //
import { AngularFireAuthModule } from '@angular/fire/auth';

// ******* firebase firestore ******** //
import { AngularFirestoreModule } from '@angular/fire/firestore';

// ******* firebase storage ******** //
import { AngularFireStorageModule } from '@angular/fire/storage';

// ******* Native Plugins ******** //
import { GooglePlus } from '@ionic-native/google-plus/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ComponentsModule,
    BrowserAnimationsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    GooglePlus,
    FirebaseAuthentication,
    AuthorizeProvider, CourseProvider, FirestoreProvider, NotifyProvider, ScoreProvider,
         ShootGroupsProvider, ShootsProvider, SponsorsProvider, StationsProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
