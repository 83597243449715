import { Component } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { AuthorizeProvider, FireUser } from '../../providers/authorize/authorize';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'google-login',
  templateUrl: './google-login.html',
  styleUrls: ['./google-login.scss'],
})
export class GoogleLoginComponent {

  user: Observable<FireUser>;

  constructor(public afAuth: AngularFireAuth,
              private platform: Platform,
              private auth: AuthorizeProvider,
              private afs: AngularFirestore) {
    // this.user = this.afAuth.authState;
    this.user = auth.user
    .pipe(
      switchMap(user => {
        if (user) {
          // logged in, get custom user from Firestore
          return this.afs.doc<FireUser>(`users/${user.uid}`).valueChanges();
        } else {
          // logged out, null
          // return Observable.of(null);
          return Observable;
        }
      })
      );

  }

  googleLogin() {
    console.log('logging in');
    this.auth.googleLogin();
  }

  signOut() {
    // this.afAuth.auth.signOut();
    this.auth.signOut();
  }

  editUser() {
      // this.navCtrl.push(ProfilePage);
  }
}
