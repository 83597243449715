import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
/*
  Generated class for the StationsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export interface Station {
  num: number;
  status: string;
  updated_at: Date;
  course: string;
  [key: string]: any;
}
@Injectable()
export class StationsProvider {
  stationCollection: AngularFirestoreCollection<Station>;
  selectedStation: AngularFirestoreDocument<Station>;
  station: Observable<Station>;


  constructor(private afs: AngularFirestore) {
    console.log('Hello StationsProvider Provider');
  }

  getStationsByCourse(courseId: string): Observable<Station[]> {
    const stationCollection: AngularFirestoreCollection<Station> = this.afs.collection('stations', (ref) => ref
      .orderBy('num', 'asc')
      .where('status', '==', 'published')
      .where('course', '==', courseId));

    return stationCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Station;
        return { id: a.payload.doc.id, num: data.num, status: data.status, course: data.course,
          updated_at: data.updated_at };
      });
    }));
  }

}
