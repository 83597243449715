import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { GoogleLoginComponent } from './google-login/google-login';
import { SettingsComponent } from './settings/settings';
import { AlertsComponent } from './alerts/alerts';
import { ScoreComponent } from './score/score';

@NgModule({
    declarations: [GoogleLoginComponent,
        SettingsComponent,
        AlertsComponent,
        ScoreComponent],
    imports: [
        CommonModule, // <--- for angular directives
        IonicModule],  // <--- for ionic components,
    exports: [GoogleLoginComponent,
        SettingsComponent,
        AlertsComponent,
        ScoreComponent]
})
export class ComponentsModule {}
