import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
/*
  Generated class for the ShootGroupsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export interface ShootGroups {
  uid: string;
  updated_at?: Date;
  course: string;
  shooters: Array<Shooters>;
  [key: string]: any;
}

export interface Shooters {
  displayName: string;
  uid: string;
  type?: string;
  photoURL?: string;
}

@Injectable()
export class ShootGroupsProvider {
  shootGroupsCollection: AngularFirestoreCollection<ShootGroups>;

  constructor(
    private afs: AngularFirestore,
    private af: AngularFireAuth) {
      console.log('Hello ShootGroupsProvider Provider');
      af.user.subscribe(user => {
      console.log('ShootGroupsProvider - user');
      this.loadShootGroupsCollection(user.uid);
    });
  }

  loadShootGroupsCollection(uid: string) {
    console.log('loadShootGroupsCollection =>', uid);
    this.shootGroupsCollection = this.afs.collection('groups',
      (ref) =>
      ref.orderBy('course', 'desc')
      .where('user.uid', '==', uid)
    );
  }

  getShootGroupsCollection(userId: string, courseId: string): Observable<ShootGroups[]>  {
    console.log('getShootGroupsCollection.userId =>', userId);
    console.log('getShootGroupsCollection.courseId =>', courseId);
    // this.shootGroupsCollection
    const shootGroupsCollection: AngularFirestoreCollection<ShootGroups> = this.afs.collection('groups',
      (ref) => ref
      .where('uid', '==', userId)
      .where('course', '==', courseId)
    );

    return shootGroupsCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as ShootGroups;
        return { id: a.payload.doc.id, uid: data.uid,
          updated_at: data.updated_at, course: data.course, shooters: data.shooters};
      });
    }));
  }

  getShootGroups() {
    const shootGroups = this.shootGroupsCollection.valueChanges();
    return shootGroups;
  }

  async createShootGroup(shootGroup: ShootGroups) {
    // console.log('Create Score =>', JSON.stringify(score));
    return await this.shootGroupsCollection.add(shootGroup);
  }

  updateShootGroup(id: string, data: Partial<ShootGroups>) {
    console.log('Provider.updateShootGroup =>', id, data);
    data.updated_at = new Date();
    return this.getShootGroup(id).update(data);
  }

  getShootGroup(id: string) {
    return this.afs.doc<ShootGroups>(`groups/${id}`);
  }

}
