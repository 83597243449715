import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Shoot } from '../../providers/shoots/shoots';
import { Course } from '../../providers/course/course';
import { AuthorizeProvider, FireUser } from '../authorize/authorize';
// import { AngularFireAuth } from 'angularfire2/auth';

/*
  Generated class for the ScoreProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

export interface Score {
  updated_at?: Date;
  shoot?: Shoot;
  course?: Course;
  user?: any;
  station?: number;
  scoreValue?: number;
  [key: string]: any;
}

export interface TopScore {
  user?: any;
  scoreValue?: number;
}

export interface Metrics {
  user?: FireUser;
  course?: Course;
  scores?: ScoreMetrics;
  updated_at?: Date;
  [key: string]: any;
}

export interface ScoreMetrics{
  total?: number;
  updated_at?: Date;
}

@Injectable()
export class ScoreProvider {
  scoreDoc: AngularFirestoreDocument<Score>;
  scoreCollection: AngularFirestoreCollection<Score>;
  metricCollection: AngularFirestoreCollection<Metrics>;
  score: Observable<Score>;
  // user: FireUser;
  // user: FireUser; // = <FireUser>{};
  _user: FireUser;
  get user(): FireUser {
    return this._user;
  }
  constructor(
    private afs: AngularFirestore,
    private _auth: AuthorizeProvider) {
    console.log('Hello ScoreProvider Provider');
    this._user = this.getUser();
    console.log('user loaded', this._user);
  }

  loadScoreCollection(user: FireUser) {
    console.log('this user', user);
    this.scoreCollection = this.afs.collection('scores', 
      (ref) => ref.orderBy('course', 'desc')
      // .where('user.uid', '==', user.uid)
    );
  }

  loadMetricCollection(user: FireUser) {
    console.log('this user', user);
    this.metricCollection = this.afs.collection('metrics', 
      (ref) => ref.orderBy('updated_at', 'desc')
      // .where('user.uid', '==', user.uid)
    );
  }

  loadScore(scoreId?: string): Observable<Score> {
    this.scoreDoc = this.afs.doc<Score>('scores/' + scoreId);
    this.score = this.scoreDoc.valueChanges();
    return this.score;
  }

  getScore(id: string) {
    return this.afs.doc<Score>(`scores/${id}`);
  }

  getMetric(id: string) {
    return this.afs.doc<Metrics>(`metrics/${id}`);
  }

  getScoresByCourse(courseId: string): Observable<Score[]> {
    let _scoreCollection: AngularFirestoreCollection<Score> = this.afs.collection('scores', (ref) => ref
      .orderBy('course', 'asc')
      .orderBy('station', 'asc')
      .where('course.id', '==', courseId)
    );

    return _scoreCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Score;
        return { id: a.payload.doc.id, course: data.course, shoot: data.shoot,
          user: data.user, station: data.station, scoreValue: data.scoreValue, updated_at: data.updated_at };
      });
    }));
  }

  getScoresByCourseByUser(courseId: string, uid: string): Observable<Score[]> {
    let _scoreCollection: AngularFirestoreCollection<Score> = this.afs.collection('scores', (ref) => ref
      .orderBy('course', 'asc')
      .orderBy('station', 'asc')
      .where('course.id', '==', courseId)
      .where('user.uid', '==', uid));

    return _scoreCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Score;
        return { id: a.payload.doc.id, course: data.course, shoot: data.shoot,
          user: data.user, station: data.station, scoreValue: data.scoreValue, updated_at: data.updated_at };
      });
    }));
  }

  getMetricsByUserCourse(course: Course, user: FireUser): Observable<Metrics[]> {
    console.log('getMetrics By User => ', user, 'course:', course);
    // const document: AngularFirestoreDocument<Metrics> = afs.document('metrics/' + someDocId)
    // const document$: Observable<Item> = document.valueChanges()
    let _metricsCollection: AngularFirestoreCollection<Metrics> = this.afs.collection('metrics', (ref) => ref
      .orderBy('course.name', 'asc')
      // .orderBy('station', 'asc')
      .where('course.id', '==', course.id)
      .where('user.uid', '==', user.uid));

    return _metricsCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Metrics;
        return { id: a.payload.doc.id, user: data.user, course: data.course,
          scores: data.scores, updated_at: data.updated_at };
      });
    }));
  }

  getMetricsByCourse(course: Course): Observable<Metrics[]> {
    console.log('getMetricsByCourse => ', 'course:', course);
    const metricsCollection: AngularFirestoreCollection<Metrics> = this.afs.collection('metrics', (ref) => ref
      .orderBy('course.name', 'asc')
      .orderBy('scores.total', 'desc')
      .where('course.id', '==', course.id));

    return metricsCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Metrics;
        return { id: a.payload.doc.id, user: data.user, course: data.course,
          scores: data.scores, updated_at: data.updated_at };
      });
    }));
  }

  getMetricsByShoot(shoot: Shoot): Observable<Metrics[]> {
    console.log('getMetricsByShoot => ', 'course:', shoot);
    const metricsCollection: AngularFirestoreCollection<Metrics> = this.afs.collection('metrics', (ref) => ref
      .orderBy('course.name', 'asc')
      .orderBy('scores.total', 'desc')
      .where('shoot.id', '==', shoot.id));

    return metricsCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data() as Metrics;
        return { id: a.payload.doc.id, user: data.user, course: data.course,
          scores: data.scores, updated_at: data.updated_at };
      });
    }));
  }

  async create(score: Score, user: FireUser) {
    score.user = user;
    // console.log('Create Score =>', JSON.stringify(score));
    return await this.scoreCollection.add(score);
  }

  update(id: string, data: Partial<Score>) {
    return this.getScore(id).update(data);
  }

  createMetric(metric: Metrics, user: FireUser) {
    metric.user = user;
    this.metricCollection.add(metric);
    console.log('metric created:', metric);

    // export interface Metrics {
    //   user?: FireUser;
    //   course: Course;
    //   scores: ScoreMetrics;
    //   updated_at?: Date;
    //   [key: string]: any;
    // }

    // export interface ScoreMetrics{
    //   total: number;
    //   updated_at?: Date;
    // }
  }

  updateMetric(id: string, data: Partial<Metrics>) {
    console.log('updating metric data:', data);
    this.getMetric(id).update(data);
  }

  getUser() {
    let u: FireUser = {} as FireUser;
    this._auth.user.subscribe(user => {
      u = {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified || null,
        isAnonymous: user.isAnonymous || false,
        phoneNumber: user.phoneNumber || null,
        providerData: user.providerData || null,
        photoURL: user.photoURL || 'https://app.shoothero.us/assets/icon/auth-logo.png',
        providerId: user.providerId || null,
        uid: user.uid
      };
      console.log('loading user =>', user);
      this.loadScoreCollection(user);
      this.loadMetricCollection(user);
    });
    return u;
  }

  // NonFirebase Functions
  getScoringArray(scoring: string): Array<number> {
    const score = scoring.split(',');
    const array: Array<number> = [];
    score.forEach(num => {
      array.push(Number(num));
    });
    return array;
  }

  calcAvailableHighestPossibleScore(scoring: string, stations: number): number {
    const hs = this.getScoringArray(scoring).slice(-1).pop();
    return hs * stations;
  }

}
